<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>Solicitudes de afiliación</h1>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="joinRequest"
          :items-per-page="10"
          :loading="loading"
          sort-by="createdAt"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn fab rounded x-small color="info" @click="showInfo(item)">
              <v-icon> fas fa-info </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      scrollable
      v-if="infoDialog"
      v-model="infoDialog"
      max-width="1100px"
    >
      <information
        :item="selectedItem"
        @cancel="infoDialog = false"
      ></information>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import information from "./information.vue";
// import editPaymentGateway from "./edit-payment-gateway";

export default {
  name: "joinus",
  components: {
    lottie: Lottie,
    information,
    // editPaymentGateway,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      //   saving: false,
      snackbar: false,
      snackbarText: "",
      joinRequest: [],

      selectedItem: null,
      infoDialog: false,

      headers: [
        {
          text: "Nombre del comercio",
          value: "agency.name",
        },
        {
          text: "Usuario",
          value: "user.name",
          width: "250px",
        },
        {
          text: "Teléfono",
          value: "user.phone",
          width: "200px",
        },
        {
          text: "Barrio/Colonia",
          value: "agency.suburb",
        },

        {
          text: "Fecha de creación",
          value: "createdAt",
          width: "250px",
        },
        {
          text: "",
          value: "options",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MMMM/YYYY @ hh:mm A");
    },
  },

  methods: {
    showInfo(item) {
      this.selectedItem = item;
      this.infoDialog = true;
    },
  },
  mounted() {
    this.$store.state.visibleSearch = true;
    this.$binding("joinRequest", db.collection("joinRequest")).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
