<template>
  <v-card>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Información de afiliación</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pl-10">
      <v-row class="mt-0">
        <v-col class="mb-5" cols="12" sm="12" md="12">
          <v-stepper non-linear flat v-model="e1">
            <v-stepper-header>
              <v-stepper-step editable step="1">
                Información de contacto
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="2">
                Información del comercio
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3">
                Información legal
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items class="ma-0 pa-0 mt-10">
              <v-stepper-content class="ma-0 pa-0" step="1">
                <v-text-field
                  rounded
                  filled
                  readonly
                  hide-details
                  v-model="item.user.name"
                  style="border-radius: 10px"
                  label="Nombre completo"
                />
                <v-text-field
                  rounded
                  class="mt-5"
                  style="border-radius: 10px"
                  v-model="item.user.email"
                  filled
                  readonly
                  hide-details
                  label="Correo electrónico"
                />

                <v-text-field
                  rounded
                  class="mt-5"
                  filled
                  readonly
                  hide-details
                  style="border-radius: 10px"
                  v-model="item.user.phone"
                  label="Número de teléfono"
                />
              </v-stepper-content>

              <v-stepper-content class="ma-0 pa-0" step="2">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  readonly
                  v-model="item.agency.name"
                  style="border-radius: 10px"
                  label="Nombre del comercio"
                />
                <v-text-field
                  rounded
                  filled
                  class="mt-5"
                  hide-details
                  readonly
                  type="tel"
                  v-model="item.agency.phone"
                  style="border-radius: 10px"
                  label="Teléfono del comercio"
                />

                <v-text-field
                  rounded
                  filled
                  class="mt-5"
                  hide-details
                  readonly
                  v-model="item.agency.attendant"
                  style="border-radius: 10px"
                  label="Encargado del comercio"
                />

                <v-text-field
                  rounded
                  filled
                  class="mt-5"
                  hide-details
                  readonly
                  type="tel"
                  v-model="item.agency.attendantPhone"
                  style="border-radius: 10px"
                  label="Teléfono del Encargado del comercio"
                />

                <v-select
                  v-model="item.agency.city"
                  :items="cities"
                  label="Seleccione la ciudad"
                  filled
                  class="mt-5"
                  hide-details
                  readonly
                  style="border-radius: 10px"
                  rounded
                />

                <v-text-field
                  rounded
                  filled
                  class="mt-5"
                  hide-details
                  readonly
                  v-model="item.agency.suburb"
                  style="border-radius: 10px"
                  label="Colonia/Barrio donde se encuentra el comercio"
                />

                <v-textarea
                  rounded
                  filled
                  class="mt-5"
                  readonly
                  rows="2"
                  v-model="item.agency.address"
                  style="border-radius: 10px"
                  label="Descripción de la dirección"
                />
              </v-stepper-content>

              <v-stepper-content class="ma-0 pa-0" step="3">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  readonly
                  v-model="item.tin.name"
                  style="border-radius: 10px"
                  label="Razón social"
                />
                <v-text-field
                  rounded
                  filled
                  hide-details
                  readonly
                  type="tel"
                  v-model="item.tin.number"
                  class="mt-5"
                  style="border-radius: 10px"
                  label="RTN"
                />

                <v-select
                  label="Seleccione el banco"
                  :items="banks"
                  class="mt-5"
                  rounded
                  filled
                  hide-details
                  readonly
                  style="border-radius: 10px"
                  v-model="item.bank.code"
                />

                <v-select
                  v-model="item.bank.accountType"
                  :items="accountsType"
                  label="Seleccione tipo de cuenta"
                  filled
                  hide-details
                  readonly
                  style="border-radius: 10px"
                  rounded
                  class="mt-5"
                />

                <v-select
                  v-model="item.bank.currency"
                  :items="currency"
                  style="border-radius: 10px"
                  label="Seleccione la moneda"
                  filled
                  hide-details
                  readonly
                  rounded
                  class="mt-5"
                />

                <v-text-field
                  v-model="item.bank.number"
                  style="border-radius: 10px"
                  label="Número de cuenta"
                  filled
                  hide-details
                  readonly
                  type="tel"
                  rounded
                  class="mt-5"
                />

                <v-text-field
                  v-model="item.bank.email"
                  style="border-radius: 10px"
                  label="Correo contable"
                  hint="Aquí se enviarán los comprobantes de los depósitos realizados"
                  filled
                  hide-details
                  readonly
                  rounded
                  class="mt-5"
                />
              </v-stepper-content>

              <v-stepper-content class="ma-0 pa-0" step="4">
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "joinus-information",
  props: ["item"],
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      e1: 1,
      cities: ["San Pedro Sula", "Tegucigalpa"],
      currency: [
        { text: "Lempiras (L)", value: "lps" },
        { text: "Dólares ($)", value: "usd" },
      ],
      accountsType: [
        { text: "Ahorro", value: "savings" },
        { text: "Cheques", value: "checkings" },
      ],
      banks: [
        { value: 1, text: "Banco Central de Honduras" },
        { value: 2, text: "Banco Atlántida" },
        { value: 3, text: "Banco Ficohsa CB" },
        { value: 4, text: "Banco de Trabajadores" },
        { value: 5, text: "Banco de Occidente" },
        { value: 6, text: "Banco de Honduras" },
        { value: 7, text: "Banco Hondureño del Cafe (Banhcafe)" },
        { value: 8, text: "Banco del País (Banpais)" },
        { value: 9, text: "Banco Lafise" },
        { value: 10, text: "Banco Ficensa" },
        { value: 11, text: "BAC Honduras" },
        { value: 12, text: "Banco Promerica" },
        { value: 13, text: "Banco Ficohsa" },
        { value: 14, text: "Banco Davivienda" },
        { value: 15, text: "BanRural" },
        { value: 16, text: "Banco Azteca" },
        { value: 17, text: "Banco Nacional de Desarrollo Agricola( Banadesa)" },
        { value: 18, text: "Financiera CrediQ" },
        { value: 19, text: "Banco Popular" },
      ],
    };
  },

  methods: {},
  async mounted() {},
};
</script>
